import { request } from '@/utils'

// 添加飞机
export const add_info = data => request.post('/api/aircraft/', data)


// 获取飞机列表
export const get_list = params => request.get('/api/aircraft/', { params })


// 获取飞机详细信息
export const get_info = id => request.get(`/api/aircraft/${id}/`)


// 修改飞机信息
export const edit_info = data => request.patch(`/api/aircraft/${data.id}/`, data)


// 删除飞机
export const del_info = id => request.delete(`/api/aircraft/${id}/`)
