<template>
    <div :is="layout">
        <v-container fluid>
            <v-row align="center">
                <v-col cols="3">
                    <v-text-field @keypress.enter="doGet(1)" v-model="search_info.number"
                                  :label="$t('flight.number')"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-btn @click="doGet(1)" large color="accent">{{$t('search')}}</v-btn>
                    <v-btn @click="upload_file" large color="accent" style="left: 10px">{{$t('import')}}</v-btn>
                    <input @change="exec_upload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display: none" ref="inputFile" type="file">
                </v-col>
                <v-col cols="1" offset="5">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   small
                                   fab
                                   class="float-right mr-4"
                                   color="secondary2"
                                   @click="selectdialog=true"
                            >
                                <i class="airport icon-gantetu"
                                   style='font-size: 25px !important;'></i>
                            </v-btn>
                        </template>
                        <span class="white--text">{{$t("flight.gantt")}}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <table-list :list="list" :listCount="list_count" @change="changePage">
                <template #icon>
                    <i class="airport icon-zu icon_font"></i>
                </template>
                <template #item="{item}">
                    <div  @click="opend_flight_info_dialog(item)" style="cursor: pointer;">
                        <div style="margin-bottom:10px">{{item.number}}</div>
                        <div class="small_font">{{$t('flight.origin')}}: {{get_airport(item.takeoff_airport)}}</div>
                        <div class="small_font">{{$t('flight.destination')}}: {{get_airport(item.land_airport)}}</div>
                        <div class="small_font">{{$t('flight.time')}}: {{item.takeoff_time}} {{$t('flight.to')}}
                            {{item.land_time}}
                        </div>
                    </div>
                </template>
                <template #active="{item}">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   color="secondary"
                                   outlined
                                   text
                                   @click="edit_open(item)"
                                   v-on="on" x-small fab>
                                <i class="airport small-font icon-bianji "
                                   style='font-size: 18px !important; color: #b5bac1'></i>
                            </v-btn>
                        </template>
                        <span class="white--text">{{$t("edit")}}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   color="secondary"
                                   text
                                   outlined
                                   v-on="on"
                                   @click="del(item)"
                                   class="ml-2" depressed x-small fab>
                                <i class="airport small-font icon-shanchu"
                                   style='font-size: 18px !important; color: #b5bac1'></i>
                            </v-btn>
                        </template>
                        <span class="white--text">{{$t("delect")}}</span>
                    </v-tooltip>
                </template>
            </table-list>
        </v-container>
        <v-dialog v-model="selectdialog" width="800px">
            <v-card color="secondary">
                <v-card-title>
                    {{$t('flight.preview')}}
                </v-card-title>
                <v-container class="pd-20">
                    <v-radio-group v-model="radioGroup">
                        <v-radio @click="selecttime=false" class="pb-3" text-h3 value="1"
                                 :label="$t('flight.selectAll')"></v-radio>
                        <v-radio @click="selecttime=true" text-h3 value='0' :label="$t('flight.selectTime')"></v-radio>
                    </v-radio-group>
                    <div v-if="selecttime">
                        <v-row>
                            <v-col cols="6">
                                <v-datetime-picker :label="$t('flight.start_time')" v-model="start_time"
                                                   time-format="HH:mm:ss" class="text"></v-datetime-picker>
                            </v-col>
                            <v-col cols="6">
                                <v-datetime-picker :label="$t('flight.end_time')" v-model="end_time"
                                                   time-format="HH:mm:ss" class="text"></v-datetime-picker>
                            </v-col>
                        </v-row>
                    </div>

                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submitGantt" color="accent">{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn @click="add_open" color="accent" fab class="float-btn">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog width="800px" v-model="dialog_flight_info">
            <v-card color="secondary">
                <v-card-title>航班详情</v-card-title>
                <v-container class="pa-5">
                    <v-row>
                        <v-col cols="12">
                            <div class="map_wrapper">
                                <div id="Map"></div>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.number')}}:
                            <span>
                                {{current_info.number}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.aircraft_id')}}:
                            <span>
                                {{current_info.aircraft_id}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.takeoff_time')}}:
                            <span>
                                {{current_info.takeoff_time}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.takeoff_airport')}}:
                            <span>
                                {{(current_info.takeoff_airport)}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.takeoff_runway')}}:
                            <span>
                                {{current_info.takeoff_runway}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.takeoff_seat')}}:
                            <span>
                                {{current_info.takeoff_seat}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.takeoff_taxiing_time')}}:
                            <span>
                                {{current_info.takeoff_taxiing_time}}
                            </span>
                        </v-col>

                        <v-col cols="6">
                            {{$t('flight.land_date')}}:
                            <span>
                                {{current_info.land_time}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.land_airport')}}:
                            <span>
                                {{(current_info.land_airport)}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.land_runway')}}:
                            <span>
                                {{current_info.land_runway}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.land_seat')}}:
                            <span>
                                {{current_info.land_seat}}
                            </span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('flight.land_taxiing_tiem')}}:
                            <span>
                                {{current_info.land_taxiing_tiem}}s
                            </span>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog_flight_info = false" color="accent">关闭</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" width="800px">
            <v-card color="secondary">
                <v-card-title>{{$t(edit_mode ? 'flight.edit_flight' : 'flight.add_flight')}}</v-card-title>
                <v-container class="pd-30">
                    <v-row>
                        <v-col cols="8">
                            <v-text-field v-model="info.number" :label="$t('flight.number')"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-autocomplete :items="aircraft_list" item-value="id" item-text="name" v-model="info.aircraft_id" :label="$t('flight.aircraft_id')"></v-autocomplete>
                        </v-col>
                        <v-col cols="8">
                            <v-datetime-picker :label="$t('flight.takeoff_time')" time-format="HH:mm"
                                               v-model="takeoffTime"></v-datetime-picker>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete :items="airport_list" item-value="id" item-text="name"
                                      v-model="info.takeoff_airport"
                                      :label="$t('flight.takeoff_airport')"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete :items="starting_point_list" item-value="id" item-text="name"
                                      v-model="info.takeoff_runway" :label="$t('flight.takeoff_runway')"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete item-value="id" item-text="group" :items="aircraftnode_list"
                                      v-model="info.takeoff_seat" :label="$t('flight.takeoff_seat')"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :suffix="$t('seconds')" v-model="info.takeoff_taxiing_time"
                                          :label="$t('flight.takeoff_taxiing_time')"></v-text-field>
                        </v-col>

                        <v-col cols="8">
                            <v-datetime-picker :label="$t('flight.land_date')" time-format="HH:mm" v-model="landTime"></v-datetime-picker>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete :items="airport_list" item-value="id" item-text="name" v-model="info.land_airport"
                                      :label="$t('flight.land_airport')"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete :items="starting_point_list" item-value="id" item-text="name"
                                      v-model="info.land_runway" :label="$t('flight.land_runway')"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete item-value="id" item-text="group" :items="aircraftnode_list"
                                      v-model="info.land_seat" :label="$t('flight.land_seat')"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :suffix="$t('seconds')" type="number" v-model="info.land_taxiing_tiem"
                                          :label="$t('flight.land_taxiing_tiem')"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit" color="accent">{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import * as requestFlight from '@/api/flight'
    import {get_path, get_info} from "../../../api/airport_data";
    import {get_list} from "../../../api/airport";


    import {get_list as get_aircraft_list, get_info as get_aircraft_info} from '../../../api/aircraft'

    let moment = require('moment')
    import TableList from '@/components/TableList'

    export default {
        components: {TableList},
        data: () => ({
            list: [],
            dialog_flight_info: false,
            airport_id: null,
            aircraft_list: [], // 飞机列表
            edit_mode: false,
            search_info: {
                pageindex: 1,
                pagesize: 10,
                number: ''
            },
            current_info: {
                aircraft_id: 34,
                id: 77,
                land_airport: 2,
                land_runway: "28206",
                land_seat: 29850,
                land_taxiing_tiem: 30,
                land_time: "2021-11-09 07:03:00",
                number: "CA6650",
                takeoff_airport: 1,
                takeoff_runway: 28223,
                takeoff_seat: 30516,
                takeoff_taxiing_time: 30,
                takeoff_time: "2021-11-08 07:03:00",
                wake_interval: ""
            },
            radioGroup: "1",
            selecttime: false,
            start_time: null,
            end_time: null,
            type: null,
            table_loading: false,
            dialog: false,
            selectdialog: false,
            layout: null,
            aircraftnode_list: [],
            starting_point_list: [],
            list_count: 0,
            airport_list: [], // 机场列表
            info: {
                number: '',
                wake_interval: '',
                land_runway: '',
                land_taxiing_tiem: '',
                land_airport: '',
                land_time: '',
                land_seat: '',
                takeoff_time: '',
                takeoff_taxiing_time: '',
                takeoff_runway: '',
                takeoff_seat: '',
                takeoff_airport: '',
            },
            add_loading: false,
            landTime: '',
            takeoffTime: '',
        }),
        watch: {
            'landTime'(v) {
                this.info.land_time = this.getTime((new Date(v)).getTime() / 1000, "yyyy-MM-dd hh:mm:ss")
            },
            'takeoffTime'(v) {
                this.info.takeoff_time = this.getTime((new Date(v)).getTime() / 1000, "yyyy-MM-dd hh:mm:ss")
            },
        },
        methods: {
            // start uploading file to the server
            exec_upload(e) {
                const file = e.currentTarget.files[0]


                const generate_randomint = (min, max) => Math.floor((max-min) * Math.random() + min)

                const file_name = generate_randomint(100000000, 999999999) + '.' + file.name.split('.')[file.name.split('.').length - 1]
                const closeLoading = this.$loading()
                cos.putObject({
                    Bucket: 'cauc-atc-1255710621', /* 必须 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: `public_picture/excel/flight/${this.getTime((new Date()).getTime() / 1000, "yyyy-MM-dd")}/${file_name}`,   /* 必须 */
                    StorageClass: 'STANDARD',
                    Body: file, // 上传文件对象
                }, (err, data) => {
                    // 在这里上传完成之后通知服务器取数据,并且更新到数据库
                    requestFlight.import_file({id: 1, file_path: data.Location}).then(res => {
                        if (res.code) {
                            return this.$toast({
                                title: this.$t('success'),
                                message: res.msg,
                            })
                        }
                        return this.$toast({
                            title: this.$t('fail'),
                            message: res.msg
                        })
                    })


                    this.$refs.inputFile.value = ''
                    closeLoading()
                });
            },
            // click upload button action
            upload_file() {
                this.$refs.inputFile.click()
            },
            opend_flight_info_dialog(_data) {
                const closeLoading = this.$loading()
                const data = JSON.parse(JSON.stringify(_data))
                const get_airport_info = (airport_id) => {
                    console.log(airport_id, 'airport_id')
                    for (let i of this.airport_list) {
                        if (i.id == airport_id) return i;
                    }
                    return {}
                }
                const land_airport_info = get_airport_info(data.land_airport)
                const takeoff_airport_info = get_airport_info(data.takeoff_airport)
                this.current_info = JSON.parse(JSON.stringify(data))
                Promise.all([get_info(data.land_runway), get_info(data.takeoff_runway), get_info(data.takeoff_seat), get_info(data.land_seat), get_aircraft_info(data.aircraft_id)]).then(res => {
                    this.current_info.land_runway = res[0].name || res[0].group
                    this.current_info.takeoff_runway = res[1].name || res[1].group
                    this.current_info.takeoff_seat = res[2].name || res[2].group
                    this.current_info.land_seat = res[3].name || res[3].group
                    this.current_info.aircraft_id = res[4].name
                    this.current_info.land_airport = land_airport_info.name
                    this.current_info.takeoff_airport = takeoff_airport_info.name
                }).finally(() => {
                    this.dialog_flight_info = true
                    this.$nextTick(() => {
                        const uluru = {lat: 55, lng: 150};
                        this.markers = [];
                        this.map = new google.maps.Map(document.getElementById("Map"), {
                            zoom: 2.0,
                            center: uluru,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                            gestureHandling: "greedy",
                            disableDefaultUI: true,
                            styles: this.$store.state.map_styles,
                        });
                        const flightPath = new google.maps.Polyline({
                            path: [{lat: parseFloat(land_airport_info.lat), lng: parseFloat(land_airport_info.lng)}, {lat: parseFloat(takeoff_airport_info.lat), lng: parseFloat(takeoff_airport_info.lng)}],
                            geodesic: true,
                            strokeColor: "#FF0000",
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                        });
                        flightPath.setMap(this.map)
                        closeLoading()
                    })
                })

            },
            changePage(data) {

                this.search_info.pageindex = data.page
                // this.search_info.pagesize = data.itemsPerPage
                this.doGet()
            },
            del(data) {
                this.$confirm({
                    title: this.$t('confirm_del'),
                    content: this.$t('flight.delete_flight'),
                    okText: this.$t('confirm'),
                    cancelText: this.$t('cancel')
                })
                    .then((dialog) => {
                        requestFlight.del(data.id).then(res => {
                            this.doGet()
                        })
                    })
            },
            add_open() {
                this.edit_mode = false
                this.info = {
                    // number: '',
                    land_airport: '',
                    wake_interval: '',
                    land_date: '',
                    land_time: '',
                    land_runway: '',
                    // land_use_time: '',
                    // arrival_stand_time: '',
                    arrival_stand_use_time: '',
                    leave_date: '',
                    leave_time: '',
                    leave_runway: '',
                    // leave_use_time: '',
                    aircraftnode_id: ''
                }
                this.dialog = true
            },
            edit_open(data) {
                this.edit_mode = true
                this.takeoffTime = new Date(data.takeoff_time)
                this.landTime = new Date(data.land_time)
                this.info = JSON.parse(JSON.stringify(data))
                this.info.leave_runway = Number(this.info.leave_runway)
                this.info.land_runway = Number(this.info.land_runway)
                this.dialog = true
            },
            doGet(pageindex = this.search_info.pageindex) {
                this.search_info.pageindex = pageindex
                this.search_info.airport_id = this.airport_id
                this.table_loading = true
                const closeLoading = this.$loading()
                requestFlight.get({...this.search_info}).then(res => {
                    console.log('log')
                    this.list = res.results
                    this.list_count = Math.ceil(res.count * 1 / this.search_info.pagesize);
                }).finally(() => {
                    this.table_loading = false
                    closeLoading()
                })
            },
            submit() {
                this.add_loading = true
                if (this.edit_mode) {
                    requestFlight.edit(this.info).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('flight.edit_success_flight')
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                } else {
                    requestFlight.add(this.info).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('flight.add_success_flight')
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                }
            },
            submitGantt() {
                if (this.start_time && this.end_time) {
                    this.start_time = this.formateDate(this.start_time)
                    this.end_time = this.formateDate(this.end_time)
                }
                if (this.radioGroup === '1') {
                    this.start_time = null
                    this.end_time = null
                }
                this.$router.push({
                    path: '/flight/preview',
                    query: {type: this.radioGroup, start_time: this.start_time, end_time: this.end_time, airport_id: this.airport_id}
                })
            },
            get_airport(airport_id) {
                for (let i of this.airport_list) {
                    if (i.id == airport_id) return i.name;
                }
                return '未知'
            },
            formateDate(datetime) {
                function addDateZero(num) {
                    return num < 10 ? "0" + num : num;
                }

                let d = new Date(datetime);
                let formatdatetime =
                    d.getFullYear() +
                    "-" +
                    addDateZero(d.getMonth() + 1) +
                    "-" +
                    addDateZero(d.getDate()) +
                    " " +
                    addDateZero(d.getHours()) +
                    ":" +
                    addDateZero(d.getMinutes()) +
                    ":" +
                    addDateZero(d.getSeconds());
                return formatdatetime;
            },
        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            if (!this.$route.query.airport_id && !this.$store.state.select_air_port_id) {
                this.$toast({
                    title: this.$t('error'),
                    message: this.$t('flight.error_mes'),
                    type: 'warning'
                })
                return this.$router.push('/')
            }
            this.airport_id = this.$route.query.airport_id || this.$store.state.select_air_port_id
            this.doGet()
            // 获取飞机列表
            get_aircraft_list({pagesize: 9999}).then(res => {
                this.aircraft_list = res.results
            })
            // 获取全部停机位
            get_path({type: 1, pagesize: 9999}).then(res => {
                this.aircraftnode_list = res.results
            })
            // 获取全部起点
            get_path({type: 3, pagesize: 9999}).then(res => {
                this.starting_point_list = res.results
            })
            // 获取机场列表
            get_list({pagesize: 9999}).then(res => {
                this.airport_list = res.results
            })
        },
        computed: {

        }

    }
</script>
<style lang="stylus" scoped>
    .small_font
        font-size 14px !important
        padding 2px 0px

    .icon_font
        font-size 120px !important
    span
        color #686868
    #Map
        height 300px
    .map_wrapper
        height 250px
        overflow hidden
        box-shadow:0px 15px 10px -15px #000;

    @import '~@/assets/css/global.styl'
</style>
